import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpEvent,
} from '@angular/common/http';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

const SKIPPED_DOMAINS: string[] = ['storage.googleapis.com'];

@Injectable()
export class APIInterceptor implements HttpInterceptor {
	intercept(
		req: HttpRequest<HttpEvent<unknown>>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		// Skip adding api url and error handling
		for (const domain of SKIPPED_DOMAINS) {
			if (req.url.includes(domain)) {
				return next.handle(req);
			}
		}

		// Add the api url to every request
		return next.handle(
			req.clone({
				url: `${environment.apiUrl}/${req.url}`,
				withCredentials: true,
			}),
		);
	}
}
