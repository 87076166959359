/* eslint-disable @typescript-eslint/no-explicit-any */
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	APP_INITIALIZER,
	ErrorHandler,
	Injector,
	NgModule,
} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
	NbMenuModule,
	NbSidebarModule,
	NbToastrModule,
	NbWindowModule,
} from '@nebular/theme';
import { CoreModule } from '@core/core.module';
import { ThemeModule } from '@theme/theme.module';
import {
	OrganizationService,
	UserOrganizationsResponse,
} from '@core/services/organization.service';
import { Observable } from 'rxjs';
import { BaseHttpResponse } from '@core/models/interfaces/BaseHttpResponse.interface';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { GlobalErrorHandler } from '@core/handlers/global-error.handler';

type OrganizationResponse =
	| Observable<BaseHttpResponse<UserOrganizationsResponse>>
	| Promise<boolean>;

function initializeAppFactory(
	organizationService: OrganizationService,
	router: Router,
): () => OrganizationResponse {
	return (): OrganizationResponse => {
		const currentPath = window.location.pathname;

		if (currentPath.startsWith('/replay')) return null;

		// This fixes a bug where if we try to visit '/' in without being logged in we get an error that's not catched
		// so we can simply redirect to auth, if the user is logged in he'll be redirected to '/home'
		if (currentPath === '/') {
			return router.navigate(['auth']);
		}

		if (currentPath.startsWith('/auth')) {
			return null;
		} else {
			return organizationService.findUserOrganizations();
		}
	};
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export let AppInjector: Injector;

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HammerModule,
		HttpClientModule,
		AppRoutingModule,
		NbSidebarModule.forRoot(),
		NbMenuModule.forRoot(),
		NbWindowModule.forRoot(),
		NbToastrModule.forRoot(),
		CoreModule.forRoot(),
		ThemeModule.forRoot(),
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeAppFactory,
			deps: [OrganizationService, Router, Sentry.TraceService],
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(injector: Injector) {
		AppInjector = injector;
	}
}
